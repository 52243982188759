import './App.css';
import Root from './Root';
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css"

function App() {
  return (
    <Root />
  );
}

export default App;
